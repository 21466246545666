<template>
  <q-dialog v-model="isShow" persistent>
    <q-card class="new-project modal-upload-materials">
      <q-btn icon="close" @click="closeModal" flat round dense v-close-popup />

      <q-card-section class="form-container step-two">
        <div class="file-wrapper">
          <q-file
            v-model="files"
            outlined
            use-chips
            multiple
            accept=".pdf"
            ref="file"
          >
            <template v-slot:label>
              Project description
            </template>
          </q-file>

          <div v-if="!files || !files.length" @click="$refs.file.$el.click()">
            <img src="../../assets/icons/upload.svg" />
            <p>
              Veuillez choisir des sources au format PDF contenant les
              informations utiles au projet.
            </p>
            <p>
              Liste des documents pouvant être analysés automatiquement : extraits Kbis, statuts, baux (civils et commerciaux), bilans comptables, liasses fiscales (2050 à 2059-G).
            </p>
          </div>
        </div>

        <q-linear-progress
          size="10px"
          :value="progress"
          v-if="getIsSendingNewProjectDocuments"
        />

        <div class="bottom-buttons" v-if="!getIsSendingNewProjectDocuments">
          <q-btn label="Annuler" class="btn-white" type="button" @click="skip" />
          <q-btn
            v-if="!files || !files.length"
            label="Choisir des sources"
            type="button"
            @click="$refs.file.$el.click()"
          />
          <q-btn v-else label="Importer" type="button" @click="onSubmit" />
        </div>

        <div class="bottom-buttons" v-if="getIsSendingNewProjectDocuments">
          <q-btn
            label="Arrêter l'importation"
            class="btn-cancel"
            type="button"
            @click="toggleStop(true)"
          />
        </div>
      </q-card-section>
    </q-card>

    <StopModal
      :content="contentStop"
      :is-show.sync="isStop"
      @toggleStop="toggleStop"
    />
  </q-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import StopModal from '@/components/modals/Stop'

export default {
  name: 'UploadMaterialsModal',
  components: { StopModal },
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      files: null,
      progress: 0,
      currentlyUploadedFile: 0,
      contentStop: {
        title: 'Stop uploading',
        text: ['Are you sure you want to stop uploading these materials?']
      },
      isStop: false
    }
  },
  computed: {
    ...mapGetters('projects', [
      'getIsSendingNewProjectDocuments',
      'getErrorSendingNewProjectDocuments'
    ])
  },
  methods: {
    ...mapActions('projects', [
      'sendNewProjectDocuments',
      'uploadDocumentToS3',
      'handlerSetIsSendingNewProjectDocuments'
    ]),
    async skip() {
      this.closeModal()
    },
    toggleStop(status) {
      if (!status) this.skip()
      this.isStop = status
    },
    onSubmit() {
      if (this.files) {
        this.progress = 0

        this.files.map(async file => {
          const data = {
            projectId: this.$route.params.projectId,
            documentFileName: file.name
          }

          const s3UrlObject = await this.sendNewProjectDocuments(data)
          await this.uploadDocumentToS3({ s3UrlObject, file })
          await this.uploadedDocumentItem(data.documentFileName)

          this.currentlyUploadedFile += 1
          this.progress =
            ((100 / this.files.length) * this.currentlyUploadedFile) / 100

          if (this.progress === 1) await this.skip()
        })
      }
    },
    uploadedDocumentItem(name) {
      const documents = [
        ...document.querySelectorAll('.file-wrapper span.ellipsis')
      ]
      const el = documents.filter(item => item.textContent === name)
      el[0].parentNode.classList.add('uploaded')
    },
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    },
    closeModal() {
      this.files = null
      this.progress = 0
      this.currentlyUploadedFile = 0
      this.isStop = false
      this.$emit('closeModal')
    }
  },
  watch: {
    isShow: function(status) {
      if (!status)
        this.handlerSetIsSendingNewProjectDocuments({ status: false })
    }
  }
}
</script>
